<template>
  <tr>
    <td class="w-100">
      <div class="mobile-table-item">
        <div class="mobile-table-item__select">
          <checkbox @change="$emit('update:checked')" :checked="checked" />
        </div>
        <div class="mobile-table-item__values">
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[1])"
              >
                {{ $t('table.header.name') }}
              </div>
              <div class="mobile-table-item__value">
                <span class="bold p-3">{{ item.name }}</span>
              </div>
            </div>
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[2])"
              >
                {{ $t('table.header.date') }}
              </div>
              <div
                class="mobile-table-item__value p-3"
                v-html="formatDate(item.date)"
              ></div>
            </div>
          </div>
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[3])"
              >
                {{ $t('table.header.size') }}
              </div>
              <div class="mobile-table-item__value">
                <span class="p-3">{{ item.size }}</span>
              </div>
            </div>
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[4])"
              >
                {{ $t('table.header.type') }}
              </div>
              <div class="mobile-table-item__value">
                <status-label
                  :small="true"
                  :value="item.type"
                  :status="typeColor(item.type)"
                />
              </div>
            </div>
          </div>
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__row">
              <div
                class="mobile-table-item__header"
                @click="$emit('update:sortBy', headers[5])"
              >
                {{ $t('table.header.version') }}
              </div>
              <div
                class="mobile-table-item__value p-3"
                @click="$emit('update:sortBy', headers[6])"
              >
                {{ item.version }}
              </div>
            </div>
          </div>
          <div class="mobile-table-item__group">
            <div class="mobile-table-item__actions">
              <v-btn icon small @click="$emit('action-button-download', item)"
                ><v-icon>$update</v-icon></v-btn
              >
              <v-btn icon small @click="$emit('action-button-restore', item)"
                ><v-icon>$restore</v-icon></v-btn
              >
              <v-btn icon small @click="$emit('action-button-delete', item)"
                ><v-icon>$thrash</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import StatusLabel from "../StatusLabel.vue";
import Checkbox from "../buttons/Checkbox.vue";

export default {
  components: {
    StatusLabel,
    Checkbox,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
    },
    item: Object,
  },
  methods: {
    typeColor: function (value) {
      if (value == "Automatic") {
        return "primary";
      } else if (value == "Manual") {
        return "purple";
      }
    },

    formatDate: function (value) {
      if (!value) return "---";

      if (typeof value === "string") {
        value = parseInt(value);
      }

      var options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      const formatedDate = new Date(value).toLocaleDateString(
        undefined,
        options
      );

      let date = formatedDate.slice(0, formatedDate.indexOf(","));
      let time = formatedDate.slice(
        formatedDate.indexOf(",") + 1,
        formatedDate.length
      );
      date = `<span class="font-weight-bold">${date}</span>`;
      return date + time;
    },
  },
};
</script>


<style lang="scss" scoped>
td,
tr {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.checkbox::v-deep {
  .v-input .v-input__control .v-input__slot {
    min-height: unset !important;
  }
}
</style>